// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from "./authentication"
import datatable from "../views/Components/Datatable/store/reducer"
import customer from "../views/store/reducer"
import { combineReducers } from "redux"
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['navbar']
}

var rootReducer = combineReducers({ navbar, layout, auth, datatable, customer })
rootReducer = persistReducer(persistConfig, rootReducer)
export default rootReducer
