
import logo from "@src/assets/images/logo/logo.png"

const SpinnerComponent = () => {
  return (
     <div className="fallback-spinner app-loader">
       <div className="loader">
  <div className="loader__bar"></div>
  <div className="loader__bar"></div>
  <div className="loader__bar"></div>
  <div className="loader__bar"></div>
  <div className="loader__bar"></div>
  <div className="loader__ball"></div>
</div>
        {/* <img className="fallback-logo c-sd-app-logo" src={logo} alt="logo" /> */}
        {/* <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div> */}
    </div>
  )
}


export default SpinnerComponent
